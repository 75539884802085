.circle > button.circle-button,
.circle > button.active-circle {
  background: none;
  /* padding: 40%, 40%, 40%, 40%;
    border-radius: 100%; */
  /* position: absolute;
    height: 30px;
    width: 30px; */
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 65% !important;
  height: 70% !important;
  /* top: 22.5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.circle > .holiday-indicator {
  border-radius: 50%;
  background-color: rgb(81, 145, 242);
  height: 4px;
  width: 4px;
  border-width: 0px;
  position: absolute;
  top: 40px;
  z-index: 3 !important;
}

.holiday-indicator.selected {
  background-color: white;
}

button.active-circle {
  background-color: rgb(81, 145, 242) !important;
  color: white;
  @apply dark:bg-primary !important;
}

button.active-circle:hover {
  background-color: rgb(81, 145, 242) !important;
  border-radius: 12px;
  @apply dark:bg-primary !important;
}
