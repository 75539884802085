div.body > h1, h2, h3, h4 {
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 10px;
}

div.body > h1 {
    font-size: 3em;
}

div.body > h2 {
    font-size: 2em;
}

div.body > h3 {
    font-size: 1.75em;
}

div.body > h4 {
    font-size: 1.5em;
}

div.body > p {
    margin-bottom: 10px;
}

div.body > a {
    color: blue;
    text-decoration: underline;
}

div.body > ul {
    list-style-type: circle;
    padding-left: 15px;
    margin-bottom: 10px;
}

div.body {
    margin-bottom: 50px;
}