.time-select-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 35% !important;
    display: grid;
    grid-template-columns: 2fr 3fr 2fr 3fr;
}

.react-dropdown-select {
    font-size: 0.8rem !important;
    height: 22px !important;
}

.calendar-time-select {
    justify-content: center !important;
    align-items: center !important;
}

.calendar-time-select .react-dropdown-select-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.time-select-text {
    font-size: 0.9rem;
    text-align: right;
    font-weight: bolder;
    margin: 0px !important;
}

.time-select-text-wrapper {
    display: grid;
    align-items: center !important;
    margin-right: 0.8rem;
}

.time-select-container {
    width: 100% !important;
    position: absolute;
    left: 0 !important;
}

.time-select-container div div .react-dropdown-select-content span {
    display: inline-flex !important;
    align-items: center !important;
}
/* 
.react-dropdown-select-dropdown {
    width: 100px !important;
    align-items: center !important;
} */

/* Custom Scrollbar */
.react-dropdown-select-dropdown::-webkit-scrollbar {
    width: 6px;
}

.react-dropdown-select-dropdown::-webkit-scrollbar-track {
    background-color: transparent;
}

.react-dropdown-select-dropdown::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.react-dropdown-select-dropdown::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
