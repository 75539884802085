.react-dropdown-select{
    border-radius: 0.5rem !important;
    border-width: 0 !important;
    background-color: white;
    padding: 0.5rem 0.25rem !important;
    color: #3b82f6;
    width: 100%;
    height: fit-content !important;
}
.react-dropdown-select input{
    color: #3b82f6;
    font-size: 1rem;
    line-height: 1.75rem;
}
.react-dropdown-select input::placeholder{
    color: #3b82f6;
    font-size: 1rem;
    line-height: 1.75rem;
}
.react-dropdown-select-option{
    color: #3b82f6 !important;
    background-color: transparent !important;
    border-radius: 0.5rem !important;
    padding: 0.25rem 0.5rem !important;
    border: 1px solid #3b82f6;
}
.react-dropdown-select-dropdown{
    background-color: white !important;
    color: #3b82f6;
}
.react-dropdown-select-item-selected{
    background-color:#3b82f6 !important;
}

@media (min-width: 768px) {
  .right-column {
    min-width: 45%;
  }
}

.custom-select-wrapper-time {
    position: relative;
    width: 100%;
  }
  
  .custom-select-wrapper-time .react-dropdown-select {
    width: 80% !important;
    padding-right: 30px !important;
    min-height: 20px !important;
    height: auto !important;
  }
  
  .custom-select-wrapper-time .react-dropdown-select-content {
    min-height: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
  }
  
  .custom-select-wrapper-time .react-dropdown-select-item {
    margin: 2px;
  }
  
  .custom-select-wrapper-time .react-dropdown-select-dropdown {
    width: 100% !important;
    top: 100% !important;
    bottom: auto !important;
  }
  
  .custom-select-wrapper-time .react-dropdown-select-dropdown-handle {
    position: absolute !important;
    right: 10px !important;
    top: 25px !important;
    transform: translateY(-50%) !important;
    transition: transform 0.2s ease;
  }
  
  .custom-select-wrapper-time .react-dropdown-select-dropdown-handle.open {
    transform: translateY(-50%) rotate(180deg) !important;
  }
  
  @media (max-width: 767px) {
    .custom-select-wrapper-time .react-dropdown-select {
      width: 100% !important;
    }

    .custom-select-wrapper-time {
      z-index: 10;
    }
  
    .custom-select-wrapper-time .react-dropdown-select-dropdown {
      z-index: 11;
    }
  
  }