.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.popup-content {
  background-color: rgba(244,246,252,255) !important;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 10000; 
}

body.popup-open {
  pointer-events: none;
}

.popup-open .popup-overlay {
  opacity: 1;
  pointer-events: auto;
}
